.retangulo {
  line-height: 0;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  background-color: #FF0000;
  margin: 2px;
}

.logomarca {
  height: 25px;
  width: 100px;
}

.clique:hover {
  text-decoration: underline;
}

.versao-logomarca {
  align-items: center;
  font-size: 10px;
  margin-bottom: 0px;
  margin-top: 15px;
}

.piscar {
  animation: blinker 4s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

:root {
  --size: 'landscape';
}

.agrow-w {
  background-color: #276d36 !important;
}

.sigescom-w {
  background-color: #346cb0 !important;
}

.fa-amarelo {
  color: #f4d03f;
}

.fa-azul {
  color: #3498db;
}

.fa-branco {
  color: #ffffff;
}

.fa-cinza {
  color: #bdbdbd;
}

.fa-laranja {
  color: #ffa500;
}

.fa-vermelho {
  color: #c0392b;
}

.fa-verde {
  color: #239b56;
}

.fa-roxo {
  color: #993399;
}

.fa-cinza-escuro {
  color: #28313b;
}

.fa-cinza-claro {
  color: #d9d9d9;
}

.fa-preto {
  color: #000;
}

.fa-ciano {
  color: #00ffff;
}

.bg-azul {
  background-color: #346cb0 !important;
}

.bg-verde {
  background-color: #239b56 !important;
}

.bg-verde-claro {
  background-color: #c5ecad !important;
}

.bg-cinza {
  background-color: #cccccc !important;
}

.bg-vermelho {
  background-color: #c0392b !important;
}

.bg-vermelho-claro {
  background-color: #ffa07a !important;
}

.bg-cinza-claro {
  background-color: #f9f9f9 !important;
}

.bg-cinza-escuro {
  background-color: #a4a4a4 !important;
}

.bg-ciano {
  background-color: #00ffff !important;
}

.bg-laranja {
  background-color: #ffa500 !important;
}

.bg-amarelo {
  background-color: #ffc107 !important;
}

.bg-amarelo-claro {
  background-color: #fae9b7 !important;
  color: #000;
}

.bg-cinza-fraco {
  background-color: #f5f5f5;
  color: #000;
}

.clique {
  cursor: pointer;
}

.texto-normal {
  text-transform: initial !important;
}

.divAguarde {
  left: 50%;
  margin-left: -20px;
  position: fixed;
  top: 50%;
  z-index: 1006;
}

.divAguardeFundo {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1005;
}

.negrito {
  font-weight: bold;
}

.limitar {
  overflow: hidden;
  text-overflow: ellipsis;
}

.margem-mensagem-loader {
  margin-left: -80px !important;
}

.margem-mensagem {
  margin-left: -95px !important;
}

.margem-mensagem-espera-loader {
  margin-left: -110px !important;
}

.totalizador {
  font-weight: bold;
}

.tachado {
  text-decoration: line-through;
}

.transparente {
  opacity: 0.4;
}

.somente-imprimir {
  display: none !important;
}

.relativo {
  position: relative;
}

.modo-escuro {
  background-color: #191926 !important;
}

.flatpickr-calendar.open {
  z-index: 1010;
}

.tamanho-foto-empresa {
  padding-bottom: 200px;
}

.ajuda-senha-operacional {
  margin-top: 8px;
  margin-left: 4px;
}

.tarja-nao-finalizado {
  color: rgba(255, 0, 0, 0.315);
  font-size: 100px;
  transform: rotate(-35deg);
  text-align: center;
  position: absolute;
  margin-top: 25%;
}

.quebra-pagina {
  break-after: page !important;
}

/* EDGE */
@supports (-ms-ime-align: auto) {
  .form-label-group input {
    padding-top: calc(0.75rem + 0.75rem * (2 / 3));
    padding-bottom: calc(0.75rem / 3);
  }

  .form-label-group label {
    padding-top: calc(0.75rem / 3);
    padding-bottom: calc(0.75rem / 3);
    font-size: 0.75rem;
    color: #686f76;
  }

  input[type='text']::-ms-clear {
    display: none;
  }

}

/* IE */
@media all and (-ms-high-contrast: none) {
  .form-label-group input {
    padding-top: calc(0.75rem + 0.75rem * (2 / 3));
    padding-bottom: calc(0.75rem / 3);
  }

  .form-label-group label {
    padding-top: calc(0.75rem / 3);
    padding-bottom: calc(0.75rem / 3);
    font-size: 0.75rem;
    color: #686f76;
  }
}

@media print {

  .app-main,
  .wrapper,
  .page,
  .page-inner,
  .page-section,
  .body,
  body {
    background: white !important;
    margin: 0;
    padding: 0;
    color: #000;
  }

  .naoImprimir {
    display: none !important;
  }

  .somente-imprimir {
    display: block !important;
  }

  a {
    color: #000;
  }

  a:link {
    text-decoration: none;
  }

  .impressao-simplicada {
    color: #000 !important;
    background: none !important;
  }

}