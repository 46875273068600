.folha {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 8pt;
  color: #000;
  text-transform: uppercase !important;
  padding: 1cm;
}

.relatorio-modo-escuro {
  color: #d6d8e1;
}

.relatorio-modo-escuro .input-group>.form-control:not(:first-child) {
  color: #d6d8e1;
}

.matricial {
  font-family: Courier, monospace;
  font-size: 8pt;
  font-weight: normal;
}

.matricial .border-top {
  border-top: 1px dashed #d3d3d3 !important;
}

.matricial .border-bottom {
  border-bottom: 1px dashed #d3d3d3 !important;
}

.folha-retrato {
  width: 26cm;
}

.folha-paisagem {
  width: 29.7cm;
}

.relatorio-rodape {
  bottom: 0;
  display: block;
}

.relatorio-tabela table {
  table-layout: fixed;
  width: 100%;
}

.relatorio-tabela th {
  overflow: hidden;
}

.relatorio-tabela tbody tr {
  height: auto !important;
  padding: 0 !important;
}

.relatorio-tabela.linha-divisoria table {
  border-collapse: collapse;
}

.relatorio-tabela.linha-divisoria tbody tr {
  border-bottom: 1px solid;
}

.linha-divisoria-td {
  border-bottom: 1px solid;
}

.linha-pontilhada {
  border-top: 2px dotted black;
  width: 100%;
  margin: 20px 0;
}

.relatorio-tabela.linha-divisoria tfoot tr {
  border-bottom: 1px solid;
}

.linha-divisoria-matricial tbody tr:nth-child(2) {
  border-top: 1px dashed #d3d3d3;
}

.linha-divisoria-matricial tbody tr,
.linha-divisoria-matricial tfoot tr {
  border-top: 1px dashed #d3d3d3;
}

.relatorio-tabela td {
  white-space: nowrap;
  table-layout: fixed;
  overflow: hidden;
  text-overflow: ellipsis;
}

.relatorio-tabela thead {
  background-color: #004080;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 7pt;
  height: 35px;
  text-transform: uppercase;
}

.matricial .relatorio-tabela thead th {
  color: black !important;
}

.matricial th {
  font-family: Courier, monospace;
  color: black;
  background-color: white;
  font-size: 7pt;
  font-weight: normal;
  border-top: 1px dashed #d3d3d3;
  border-bottom: 1px dashed #d3d3d3;
}

.matricial tfoot th {
  border-top: 1px dashed #d3d3d3 !important;
}

tfoot th {
  border-top: 1px solid !important;
}

.relatorio-titulo {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12pt;
  font-weight: bold;
}

.relatorio-empresa {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10pt;
  font-weight: bold;
}

.relatorio-rotulo {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 8pt;
  font-weight: bold;
  padding-right: 5px;
}

.relatorio-negrito {
  font-weight: bold;
}

.assinatura {
  margin-top: 50px !important;
  border-top: 1px solid black;
  max-width: 450px;
  margin: auto;
  width: 50%;
  text-align: center;
}

.criterios {
  padding: 10px 0 10px 0 !important;
  margin: 0 !important;
  background-color: #f7f8e0 !important;
  border-top: 1px solid black;
  text-transform: uppercase;
  color: #000 !important;
}

.matricial .criterios {
  background-color: white !important;
  border-top: 1px dashed #d3d3d3 !important;
}

.botao-adicional {
  position: fixed;
  height: 140px;
  top: calc(50% - 70px);
  right: 0 !important;
  background-color: #ccc;
  z-index: 1029;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  text-align: center;
  border-radius: 0 20px 20px 0 !important;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  cursor: pointer;
}

.botao-adicional:hover {
  background-color: #b2b2b2;
}

.page-sidebar {
  right: -1000px;
  transition: 0.5s;
  background-color: white;
}

.page-sidebar.has-open {
  top: 0 !important;
  right: 0px;
  box-shadow: 0 0 20px black;
}

.border-dark {
  border-color: black;
}

.tabela-complementar {
  width: 100%;
}

.tr {
  page-break-inside: auto;
  page-break-before: auto;
  page-break-after: auto;
}

.tabela-complementar thead {
  background: none !important;
  color: black;
}

.relatorio-modo-escuro .tabela-complementar thead {
  color: #d6d8e1;
}

.tabela-complementar thead th {
  white-space: pre-wrap;
  font-weight: bolder !important;
  font-size: 7pt;
  color: #000 !important;
}

.tabela-complementar tfoot th {
  border: none;
  font-weight: normal !important;
  color: #000 !important;
}

.relatorio-modo-escuro .tabela-complementar thead th,
.relatorio-modo-escuro .tabela-complementar tfoot th {
  color: #d6d8e1 !important;
}

.matricial.relatorio-modo-escuro .tabela-complementar th {
  background: none;
  color: #d6d8e1;
}

.tabela-complementar tr,
.tabela-complementar td {
  border: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media print {

  .folha {
    padding: 2px;
  }

  .r-teste {
    width: 26cm !important;
    min-width: 26cm !important;
    max-width: 26cm !important;
    background-color: brown !important;
  }

  .relatorio-rodape {
    position: fixed;
    display: block;
    bottom: 0;
  }

  tfoot {
    display: contents;
    position: absolute;
    bottom: 0;
  }

  .relatorio-modo-escuro .tabela-complementar thead,
  .relatorio-modo-escuro {
    color: #000 !important;
  }

  .matricial.relatorio-modo-escuro .tabela-complementar th {
    color: #000 !important;
  }

  .r-0-5 {
    width: 1.08cm !important;
    min-width: 1.08cm !important;
    max-width: 1.08cm !important;
  }

  .r-1 {
    width: 2.17cm !important;
    min-width: 2.17cm !important;
    max-width: 2.17cm !important;
  }

  .r-1-5 {
    width: 3.25cm !important;
    min-width: 3.25cm !important;
    max-width: 3.25cm !important;
  }

  .r-2 {
    width: 4.33cm !important;
    min-width: 4.33cm !important;
    max-width: 4.33cm !important;
  }

  .r-2-5 {
    width: 5.42cm !important;
    min-width: 5.42cm !important;
    max-width: 5.42cm !important;
  }

  .r-3 {
    width: 6.5cm !important;
    min-width: 6.5cm !important;
    max-width: 6.5cm !important;
  }

  .r-3-5 {
    width: 7.58cm !important;
    min-width: 7.58cm !important;
    max-width: 7.58cm !important;
  }

  .r-4 {
    width: 8.67cm !important;
    min-width: 8.67cm !important;
    max-width: 8.67cm !important;
  }

  .r-4-5 {
    width: 9.75cm !important;
    min-width: 9.75cm !important;
    max-width: 9.75cm !important;
  }

  .r-5 {
    width: 10.83cm !important;
    min-width: 10.83cm !important;
    max-width: 10.83cm !important;
  }

  .r-5-5 {
    width: 11.92cm !important;
    min-width: 11.92cm !important;
    max-width: 11.92cm !important;
  }

  .r-6 {
    width: 13cm !important;
    min-width: 13cm !important;
    max-width: 13cm !important;
  }

  .r-6-5 {
    width: 14.08cm !important;
    min-width: 14.08cm !important;
    max-width: 14.08cm !important;
  }

  .r-7 {
    width: 15.17cm !important;
    min-width: 15.17cm !important;
    max-width: 15.17cm !important;
  }

  .r-7-5 {
    width: 16.25cm !important;
    min-width: 16.25cm !important;
    max-width: 16.25cm !important;
  }

  .r-8 {
    width: 17.33cm !important;
    min-width: 17.33cm !important;
    max-width: 17.33cm !important;
  }

  .r-8-5 {
    width: 18.42cm !important;
    min-width: 18.42cm !important;
    max-width: 18.42cm !important;
  }

  .r-9 {
    width: 19.5cm !important;
    min-width: 19.5cm !important;
    max-width: 19.5cm !important;
  }

  .r-9-5 {
    width: 20.58cm !important;
    min-width: 20.58cm !important;
    max-width: 20.58cm !important;
  }

  .r-10 {
    width: 21.67cm !important;
    min-width: 21.67cm !important;
    max-width: 21.67cm !important;
  }

  .r-10-5 {
    width: 22.75cm !important;
    min-width: 22.75cm !important;
    max-width: 22.75cm !important;
  }

  .r-11 {
    width: 23.83cm !important;
    min-width: 23.83cm !important;
    max-width: 23.83cm !important;
  }

  .r-11-5 {
    width: 24.92cm !important;
    min-width: 24.92cm !important;
    max-width: 24.92cm !important;
  }

  .r-12 {
    width: 26cm !important;
    min-width: 26cm !important;
    max-width: 26cm !important;
  }
}

.r {
  width: 100%;
}

.r-0-5 {
  width: 1cm;
  min-width: 1cm;
  max-width: 1cm;
}

.r-0-8 {
  width: 1.80cm !important;
  min-width: 1.80cm !important;
  max-width: 1.80cm !important;
}

.r-1 {
  width: 2cm;
  min-width: 2cm;
  max-width: 2cm;
}

.r-1-5 {
  width: 3cm;
  min-width: 3cm;
  max-width: 3cm;
}

.r-2 {
  width: 4cm;
  min-width: 4cm;
  max-width: 4cm;
}

.r-2-5 {
  width: 5cm;
  min-width: 5cm;
  max-width: 5cm;
}

.r-3 {
  width: 6cm;
  min-width: 6cm;
  max-width: 6cm;
}

.r-3-5 {
  width: 7cm;
  min-width: 7cm;
  max-width: 7cm;
}

.r-4 {
  width: 8cm;
  min-width: 8cm;
  max-width: 8cm;
}

.r-4-5 {
  width: 9cm;
  min-width: 9cm;
  max-width: 9cm;
}

.r-5 {
  width: 10cm;
  min-width: 10cm;
  max-width: 10cm;
}

.r-5-5 {
  width: 11cm;
  min-width: 11cm;
  max-width: 11cm;
}

.r-6 {
  width: 12cm;
  min-width: 12cm;
  max-width: 12cm;
}

.r-6-5 {
  width: 13cm;
  min-width: 13cm;
  max-width: 13cm;
}

.r-7 {
  width: 14cm;
  min-width: 14cm;
  max-width: 14cm;
}

.r-7-5 {
  width: 15cm;
  min-width: 15cm;
  max-width: 15cm;
}

.r-8 {
  width: 16cm;
  min-width: 16cm;
  max-width: 16cm;
}

.r-8-5 {
  width: 17cm;
  min-width: 17cm;
  max-width: 17cm;
}

.r-9 {
  width: 18cm;
  min-width: 18cm;
  max-width: 18cm;
}

.r-9-5 {
  width: 19cm;
  min-width: 19cm;
  max-width: 19cm;
}

.r-10 {
  width: 20cm;
  min-width: 20cm;
  max-width: 20cm;
}

.r-10-5 {
  width: 21cm;
  min-width: 21cm;
  max-width: 21cm;
}

.r-11 {
  width: 22cm;
  min-width: 22cm;
  max-width: 22cm;
}

.r-11-5 {
  width: 23cm;
  min-width: 23cm;
  max-width: 23cm;
}

.r-12 {
  width: 24cm;
  min-width: 24cm;
  max-width: 24cm;
}

.r-teste {
  width: 24cm;
  min-width: 24cm;
  max-width: 24cm;
  background-color: chartreuse;
}

.relatorio-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #d3d3d3;
  color: black;
  height: 50px;
}

.atraso {
  background-color: red;
  color: white;
}

.tabela-totalizador th {
  border-bottom: 1px solid black;
}

.tabela-totalizador table {
  margin-top: 50px !important;
  margin-left: 10px !important;
}

.quebra-linha {
  white-space: pre-wrap;
}

.nao-utilizar-borda {
  border: none !important;
}

.sub-titulo {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 8pt;
  font-weight: bold;
}

.cor-fundo-legenda {
  background: #f7f8e0;
}

.sem-borda {
  border: none !important;
}

.separador {
  height: 75px;
  width: 115%;
  background-color: #f5f5f5;
  left: -70px;
  position: relative;
  margin: 28px;
}

.separador-modo-escuro {
  background-color: #191926 !important;
}

.fonte-7pt {
  font-size: 7pt;
}

.fonte-10pt {
  font-size: 10pt;
}

.largura-total {
  width: 100%;
}

.centraliza-folha {
  margin: 0 auto;
}

.c-0-6 {
  width: 0.3cm;
  min-width: 0.3cm;
  max-width: 0.3cm;
}

.c-0-6 {
  width: 0.6cm;
  min-width: 0.6cm;
  max-width: 0.6cm;
}

.c-1 {
  width: 1cm;
  min-width: 1cm;
  max-width: 1cm;
}

.c-1-2 {
  width: 1.2cm;
  min-width: 1.2cm;
  max-width: 1.2cm;
}

.c-1-3 {
  width: 1.3cm;
  min-width: 1.3cm;
  max-width: 1.3cm;
}

.c-1-9 {
  width: 1.9cm;
  min-width: 1.9cm;
  max-width: 1.9cm;
}

.negrito {
  font-weight: bold;
}